<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Nombre*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              placeholder="Nombre"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Abreviatura*"
            label-for="abbreviation"
          >
            <b-form-input
              id="abbreviation"
              v-model="formData.abbr"
              placeholder="Abreviatura"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Entrada*"
            label-for="initial-start-hour"
          >
            <b-form-timepicker
              v-model="formData.start_hour"
              locale="en"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Salida*"
            label-for="initial-end-hour"
          >
            <b-form-timepicker
              v-model="formData.end_hour"
              locale="en"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Horas trabajadas*"
            label-for="final-start-hour"
          >
            <b-form-input
              v-model="formData.worked_hours"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Horas descanso"
            label-for="final-end-hour"
          >
            <b-form-input v-model="formData.rest_hours" />
          </b-form-group>
        </b-col>

        <b-col>
          <b-row>
            <b-col md="6" />
            <b-col
              md="6"
              class="text-right"
            >
              <b-button
                type="button"
                variant="primary"
                :disabled="isBusy || $v.$invalid"
                @click="saveData"
              >
                <b-spinner
                  v-if="isBusy"
                  small
                />
                <v-icon
                  v-else
                  name="save"
                />
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {
    BForm,
    BFormGroup,
    BButton,
    BCol,
    BFormInput,
    BRow,
    BFormTimepicker,
    BSpinner,
  } from 'bootstrap-vue'
  import { required } from 'vuelidate/lib/validators'
  import CRUDMixin from '@/mixins/CRUDMixin'

  export default {
    components: {
      BForm,
      BFormGroup,
      BButton,
      BCol,
      BFormInput,
      BRow,
      BFormTimepicker,
      BSpinner,
    },
    mixins: [CRUDMixin],
    props: {
      record: {
        type: Object,
        default: null,
      },
    },
    inject: ['workshiftsRepository'],

    data() {
      return {
        formData: {
          name: '',
          abbr: '',
          start_hour: null,
          end_hour: null,
          worked_hours: null,
          rest_hours: null,
        },
        repository: 'workshiftsRepository',
        isBusy: false,
      }
    },

    watch: {
      'formData.start_hour'() {
        this.calculateHours()
      },

      'formData.end_hour'() {
        this.calculateHours()
      },
    },

    mounted() {
      const me = this
      if (me.record) {
        me.formData = { ...me.record }
      }
    },

    methods: {
      prepareDataForSubmit() {
        const me = this
        const startHour = this.formData.start_hour.split(':')
        const endHour = this.formData.end_hour.split(':')

        return {
          ...me.formData,
          start_hour: startHour[0] + ':' + startHour[1],
          end_hour: endHour[0] + ':' + endHour[1],
          rest_hours: parseFloat(this.formData.rest_hours),
        }
      },

      calculateHours() {
        const me = this
        const initialHour = me.$date(me.formData.start_hour, 'HH:mm')
        const finalHour = me.$date(me.formData.end_hour, 'HH:mm')

        me.formData.worked_hours = !Number.isNaN(finalHour.diff(initialHour, 'hour', true))
          ? Math.abs(Math.round((finalHour.diff(initialHour, 'hour', true) + Number.EPSILON) * 100) / 100)
          : ''
      },
    },

    validations: {
      formData: {
        name: {
          required,
        },
        abbr: {
          required,
        },
        start_hour: {
          required,
        },
        end_hour: {
          required,
        },
        worked_hours: {
          required,
        },
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
