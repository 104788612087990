<template>
  <div>
    <b-card
      no-body
    >
      <base-actions-table
        :columns="fields"
        :records="records"
        :busy="isBusy"
        :is-processing="isProcessing"
        :module="module"
        @creating="handleCreating"
        @editing="handleEditing"
        @deleting="handleDeleting"
      />
    </b-card>
    <b-modal
      id="workshift-modal"
      ref="addworkshift"
      title="Agregar horario"
      size="lg"
      hide-footer
    >
      <add-workshift-form
        ref="form"
        :record="selectedRecord"
        @save="save"
        @edit="edit"
      />
    </b-modal>
  </div>
</template>

<script>
  import {
    BModal, BCard,
  } from 'bootstrap-vue'
  import CRUDMixin from '@/mixins/CRUDMixin'
  import PermissionMixin from '@/mixins/PermissionMixin'
  import AddWorkshiftForm from './AddWorkshiftForm.vue'
  import BaseActionsTable from '@/components/base/BaseActionsTable'

  export default {
    name: 'Workshifts',
    inject: ['workshiftsRepository'],
    components: {
      BCard,
      BModal,
      AddWorkshiftForm,
      BaseActionsTable,
    },
    mixins: [CRUDMixin, PermissionMixin],
    data() {
      return {
        fields: [
          {
            key: 'name',
            label: 'Nombre de horario',
          },
          {
            key: 'abbr',
            label: 'Abreviatura',
          },
          {
            key: 'start_hour',
            label: 'Hora entrada',
          },
          {
            key: 'end_hour',
            label: 'Hora salida',
          },
          {
            key: 'worked_hours',
            label: 'Horas trabajadas',
          },
          {
            key: 'rest_hours',
            label: 'Horas de descanso',
          },
          {
            key: 'actions',
            label: 'Acciones',
          },
        ],
        records: [],
        filter: null,
        filterOn: [],
        selectedRecord: null,
        repository: 'workshiftsRepository',
        module: 'workshift',
        isBusy: false,
      }
    },

    async mounted() {
      const me = this
      await me.handleLoadData()
    },

    methods: {
      handleCreating() {
        this.selectedRecord = null
        this.$bvModal.show('workshift-modal')
      },

      handleEditing(record) {
        this.selectedRecord = record
        this.$bvModal.show('workshift-modal')
      },

      handleDeleting(record) {
        this.del(record.id)
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
